<template>
    <ValidationObserver v-slot="{ handleSubmit }" class="modal is-active" tag="div">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <div class="card-header">
                <h3><i class="fa-solid fa-user"></i>Supervisor: </h3><br>
                <h3><i class="fa-solid fa-calendar-days"></i>Date: </h3>

                <!-- <p>
                    ເປັນການດຶງຂໍ້ມູນສາວຈຳປາທີ່ມີສະຖານນະ
                    <b>ເຮັດວຽກ / Working</b> ແລະ
                    ສາວຈຳປາທີ່ໄດ້ອອກວຽກໃນຊ່ວງເວລາທີ່ກຳໜົດດ້ານລຸ່ມ
                </p> -->
            </div>

            <div class="card-body">
                <div class="grids is-1-desktop gap-20-desktop">
                    <div class="field no-margin">
                        <!-- <label for="" class="label">Start Date</label> -->
                        <div class="control">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <textarea class="textarea" rows="10" v-model="commentFormShop"></textarea>
                                <span class="error is-error" v-if="errors[0]">
                                   required
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="buttons">
                    <button class="button primary">
                        Save
                    </button>
                    <button class="button light-grey" @click="close()">Cancel</button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>
  
<script>
import moment from "moment";
export default {
    data: () => ({
        supervisorName: "",
        date: "",
        commentFormShop:""
    }),
    methods: {
        close() {
            this.$emit("closeReportShop");
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
  