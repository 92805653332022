<template>
  <ValidationObserver v-slot="{ handleSubmit }" class="sideform" tag="div">
    <div class="sideform-header">
      <h3>{{ shop.name }}</h3>
      <ul>
        <!-- <li>
          Status : <span>{{ shop.status }}</span>
        </li>
        <li>
          Champa :
          <span>{{ shop.fullname }}</span>
        </li> -->
        <li>
          Status: <span>{{ shop.status }}</span>
        </li>
        <li>
          Champa :
          <span class="editable" v-if="$route.query.shopId && newChampaId" @click="
            openModal = true;
          $router
            .push({
              ...$route,
              query: {
                ...$route.query,
                resigned: newHistory,
              },
            })
            .catch((err) => { });
          ">{{ shop.fullname }} <i class="fad fa-pen"></i></span>
          <a v-else-if="$route.query.shopId && newStatus === 'setup'">Please Enter Recruit Date</a>
          <a v-else-if="$route.query.shopId && newStatus === 'quit'">Your Status Is Quit</a>
          <a v-else-if="$route.query.shopId && !newChampaId" @click="openModal = true">Assign</a>
        </li>
      </ul>
    </div>
    <div class="form scrollable-auto">
      <div class="grids is-2-desktop gap-10-desktop">
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">DT Code</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div>
                  <selectsingle v-model="newZoneId" :items="area" single itemName="zoneCode"
                    :class="{ errorText: errors[0] }" required />
                </div>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Default Working ID -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Default Working ID</label>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <div class="control">
                <input v-model="newsDefWorking" type="text" class="input" placeholder="Working ID"
                  :class="{ errorText: errors[0] }" required />
              </div>
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <!-- Outlet Current Code -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Current Code</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <input v-model="newsShopCode" type="text" class="input" placeholder="Outlet current code"
                  :class="{ errorText: errors[0] }" required />
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
                <!--errorMessage -->
                <span class="error is-error" v-else-if="errorMessage === 'duplicate current code'">{{ errorMessage
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Outlet Old Code -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Old Code</label>
            <div class="control">
              <input v-model="newOldCode" type="text" class="input" placeholder="Outlet old code" />
            </div>
          </div>
        </div>

        <!-- Outlet Name -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Name</label>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <div class="control">
                <input v-model="newName" type="text" class="input" placeholder="Outlet name"
                  :class="{ errorText: errors[0] }" required />
              </div>
              <span class="error is-error" v-if="errors[0]">
                required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <!-- Setup Date -->
        <div class="column is-4">
          <div class="field">
            <label for="" class="label">Setup Date</label>
            <div class="control">
              <date-picker v-model="newSetupDate" type="date" placeholder="Select Setup Date">
              </date-picker>
            </div>
          </div>
        </div>

        <!-- Recruit Date -->
        <div class="column is-4">
          <div class="field">
            <label for="" class="label">Recruit Date</label>
            <div class="control">
              <date-picker :disabled-date="disabledDates" v-model="newRecruitDate" type="date"
                placeholder="Select Recruit Date">
              </date-picker>
            </div>
          </div>
        </div>

        <!-- Quit Date -->
        <div class="column is-4">
          <div class="field">
            <label for="" class="label">Quit Date</label>
            <div class="control" v-if="$route.query.shopId && !newChampaId">
              <date-picker :disabled-date="disabledDatesQuit" v-model="newQuitDate" type="date"
                placeholder="Select Quit Date">
              </date-picker>
            </div>
            <div class="control" v-else>
              <date-picker :disabled="true" v-model="newQuitDate" type="date" placeholder="Select Quit Date">
              </date-picker>
            </div>
          </div>
        </div>

        <!-- Supervisor -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Supervisor</label>
            <div class="control">
              <!-- <div class="select" v-if="findSupvisorId === newSupervisorId">
                <select v-model="newSupervisorId">
                  <option
                    v-for="(s, index) in surpervisor"
                    :key="index"
                    :value="s._id"
                  >
                    {{ s.name }}
                  </option>
                </select>
              </div> -->
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div>
                  <selectsingle v-model="newSupervisorId" :items="surpervisor" single itemName="name"
                    :class="{ errorText: errors[0] }" required />
                </div>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Outlet Type -->
        <div class="column is-6">
          <div class="field">
            <label for="" class="label">Outlet Type</label>
            <div class="control">
              <div class="select">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <select v-model="typeShop" :class="{ errorText: errors[0] }" required>
                    <option value="champa" selected>Champa</option>
                    <option value="BA">BA</option>
                  </select>
                  <span class="error is-error" v-if="errors[0]">
                    required
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- ReasonQuit -->
      <div class="grids is-1-desktop gap-10-desktop" v-if="$route.query.shopId && !newChampaId && newQuitDate">
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">Quit Reason</label>
            <div class="control">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <!-- <input v-model="newFreeText" type="text" class="input" /> -->
                <textarea class="textarea" rows="5" v-model="newReasonQuit" :class="{ errorText: errors[0] }"
                  required></textarea>
                <span class="error is-error" v-if="errors[0]">
                  required
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>


      <!-- Note || FreeText -->
      <div class="grids is-1-desktop gap-10-desktop">
        <div class="column is-12">
          <div class="field">
            <label for="" class="label">Note</label>
            <div class="control">
              <!-- <input v-model="newFreeText" type="text" class="input" /> -->
              <textarea class="textarea" rows="5" v-model="newFreeText"></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- google map -->
      <GoogleMap :lat="lat" :lng="lng" :distance="distance" :fetch="fetch" @emitLat="emitLat" @emitLng="emitLng"
        @emitDistance="emitDistance" />
    </div>
    <FormReportShop v-if="isFormReportShop" @closeReportShop="isFormReportShop = false" />


    <div class="sideform-footer">
      <button class="button primary" v-if="$route.query.shopId" @click="handleSubmit(UpdateShop)">
        Update
      </button>
      <button class="button primary" v-else @click="handleSubmit(AddShop)">
        Save
      </button>
      <button class="button grey" @click="close">Cancel</button>

      <!-- <button class="button primary" @click="ClickFormReportShop()">Report</button> -->
    </div>

    <AssginedEmployee v-if="openModal" @closeModal="openModal = false" />
  </ValidationObserver>
</template>

<script>
import GoogleMap from "../../components/googlemap/google-map.vue";
import AssginedEmployee from "../form/assigned.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import selectsingle from "@coms/selectsingle-search.vue";
import FormReportShop from "../ReportOutlet.vue"
export default {
  data: () => ({
    errorMessage: "",
    openModal: false,
    formActive: false,
    isFormReportShop: false,
    shop: {},
    area: [],
    surpervisor: [],
    findSupvisorId: {},
    newZoneId: "",
    newsDefWorking: "",
    newsShopCode: "",
    newName: "",
    newChampaId: "",
    newFullname: "",
    newRecruitDate: "",
    newSetupDate: "",
    newQuitDate: "",
    newSupervisorId: "",
    newStatus: "",
    newReasonQuit: "",
    dateReportShop: "",
    commentReportShop: "",
    outletIdReportShop: "",
    supervisorId: "",
    typeShop: "",
    lat: "",
    lng: "",
    distance: "",
    fetch: false,
    newOldCode: "",
    newFreeText: "",
  }),
  components: {
    DatePicker,
    selectsingle,
    AssginedEmployee,
    GoogleMap,
    FormReportShop
  },
  created() {
    this.FetchArea();
    this.FetchSupervisor();
  },
  watch: {
    "$route.query.shopId": {
      handler(val) {
        if (val) {
          this.FetchShopId();
          // setTimeout(() => {
          //   this.newSetupDate = new Date(this.newSetupDate.setHours(0, 0, 0));
          //   this.newRecruitDate = new Date(
          //     this.newRecruitDate.setHours(0, 0, 0)
          //   );
          //   this.newQuitDate = new Date(this.newQuitDate.setHours(0, 0, 0));
          // }, 100);
        }
      },
      immediate: true,
    },
    "$store.getters.getToken": {
      handler() {
        if (this.$store.getters.getToken) {
          this.FetchAdminId();
        }
      },
      immediate: true,
    },
  },
  methods: {
    disabledDates(date) {
      return (
        date <=
        new Date(
          new Date(this.newSetupDate).setDate(
            new Date(this.newSetupDate).getDate() - 1
          )
        )
      );
    },
    disabledDatesQuit(date) {
      return (
        date <=
        new Date(
          new Date(this.newRecruitDate).setDate(
            new Date(this.newRecruitDate).getDate() - 1
          )
        )
      );
    },
    emitLat(value) {
      this.lat = value;
    },
    emitLng(value) {
      this.lng = value;
    },
    emitDistance(value) {
      this.distance = value;
    },
    close() {
      this.$emit("close");
      this.$router
        .push({
          query: {
            selectedSupervisor: this.$route.query.selectedSupervisor,
            selectedStatus: this.$route.query.selectedStatus,
            page: this.$route.query.page,
            perPage: 10,
            searchOutlet: this.$route.query.searchOutlet,
            selectDT: this.$route.query.selectDT,
            type: this.$route.query.type,
          },
        })
        .catch((err) => { });
    },
    ClickFormReportShop() {
      this.isFormReportShop = true;
    },

    async AddReportShop() {
      try {
        const form = {
          date: this.dateReportShop,
          commentShop: this.commentReportShop,
          shopId: this.$route.query.shopId
        }
        await this.$axios.post(`${this.$server}api/add-report-shop`, form)

        alert("added")
      } catch (error) {

      }
    },
    async FetchArea() {
      const res = await this.$axios.get(`${this.$server}api/zone-get`);
      this.area = res.data.mapGetZone;
    },
    async FetchSupervisor() {
      const res = await this.$axios.get(`${this.$server}api/admin-get`);
      this.surpervisorAll = res.data.mapAdmin;
      this.surpervisor = this.surpervisorAll.filter(
        (i) => i.typeAdmin === "supervisor"
      );
    },
    async FetchAdminId() {
      this.$axios.defaults.headers[
        "Authorization"
      ] = this.$store.getters.getToken;
      const res = await this.$axios.get(`${this.$server}api/admin-find-id`);
      this.supervisorId = res.data.findId;
      this.findSupvisorId = this.supervisorId._id;
    },
    FetchShopId() {
      this.$axios
        .get(`${this.$server}api/shop-findid/` + this.$route.query.shopId)
        .then((res) => {
          this.shop = res.data.mapIdShop;
          this.newZoneId = this.shop.zoneId;
          this.newsDefWorking = this.shop.defaultWorkingID;
          this.newOldCode = this.shop.oldCode;
          this.newsShopCode = this.shop.shopCode;
          this.newName = this.shop.name;
          this.newChampaId = this.shop.userId;
          this.newHistory = this.shop.historyId;
          this.newFullname = this.shop.fullname;
          this.newRecruitDate = this.shop.recruitDate ? new Date(this.shop.recruitDate) : null;
          this.newSetupDate = this.shop.setupDate ? new Date(this.shop.setupDate) : null;
          this.newQuitDate = this.shop.quitDate ? new Date(this.shop.quitDate) : null;
          this.newStatus = this.shop.status;
          this.newReasonQuit = this.shop.reasonQuit;
          this.newSupervisorId = this.shop.adminId;
          this.typeShop = this.shop.typeShop;
          this.newFreeText = this.shop.freeText;
          this.lat = this.shop.lat;
          this.lng = this.shop.lng;
          this.distance = this.shop.distance;
          this.fetch = true;
          this.$nextTick(() => {
            this.fetch = false;
          });
        });
    },
    async AddShop() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        await this.$axios
          .post(`${this.$server}api/shop-add`, {
            zoneId: this.newZoneId,
            defaultWorkingID: this.newsDefWorking,
            oldCode: this.newOldCode,
            shopCode: this.newsShopCode,
            name: this.newName,
            userId: this.newChampaId,
            recruitDate: this.newRecruitDate,
            setupDate: this.newSetupDate,
            quitDate: this.newQuitDate,
            adminId: this.newSupervisorId,
            typeShop: this.typeShop,
            freeText: this.newFreeText,
            lat: this.lat,
            lng: this.lng,
            distance: this.distance,
          })
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });

              this.$emit("close");
              this.$router
                .push({
                  query: {
                    selectedSupervisor: this.$route.query.selectedSupervisor,
                    selectedStatus: this.$route.query.selectedStatus,
                    page: this.$route.query.page,
                    perPage: 10,
                    searchOutlet: "",
                    selectDT: this.$route.query.selectDT,
                    type: this.$route.query.type,
                  },
                })
                .catch((err) => { });
            }, 500);
          });
      } catch ({ response }) {
        const errorText = response.data.message;
        switch (errorText) {
          case "unique current code shop":
            return (this.errorMessage = "duplicate current code");
        }
      }
    },
    async UpdateShop() {
      try {
        //
        //Alert Loading
        this.$swal({
          title: "Loading...",
          html: "Please wait",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        })

        const updateShop = {
          id: this.$route.query.shopId,
          zoneId: this.newZoneId,
          defaultWorkingID: this.newsDefWorking,
          oldCode: this.newOldCode,
          shopCode: this.newsShopCode,
          name: this.newName,
          userId: this.newChampaId,
          recruitDate: this.newRecruitDate,
          setupDate: this.newSetupDate,
          quitDate: this.newQuitDate,
          reasonQuit: this.newReasonQuit,
          adminId: this.newSupervisorId,
          typeShop: this.typeShop,
          freeText: this.newFreeText,
          lat: this.lat,
          lng: this.lng,
          distance: this.distance,
        };
        await this.$axios
          .put(`${this.$server}api/shop-update`, updateShop)
          .then(() => {
            setTimeout(() => {
              this.$swal({
                position: "top",
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1000,
              });


              this.$emit("close");
              this.$router.push({
                query: {
                  selectedSupervisor: this.$route.query.selectedSupervisor,
                  selectedStatus: this.$route.query.selectedStatus,
                  page: this.$route.query.page,
                  perPage: 10,
                  searchOutlet: this.$route.query.searchOutlet,
                  selectDT: this.$route.query.selectDT,
                  type: this.$route.query.type,
                },
              });
            }, 500);
            // .catch((err) => {});
            // setTimeout(() => {
            //   location.reload();
            // }, 1000);
          });
      } catch ({ response }) {
        const errorText = response.data.message;
        switch (errorText) {
          case "unique current code shop":
            return (this.errorMessage = "duplicate current code");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .selected-item {
    padding: 0;
  }

  .selected-item:hover {
    cursor: pointer;
    background: var(--grey-color);
    color: var(--bg-light);
  }

  .input-group {
    .inputs {
      //   border: none;
      position: absolute;
      bottom: 0px;
      height: 100%;
      width: 100%;
      border: none;
    }

    .selects {
      position: absolute;
      display: block;
      top: 42px;
      left: 0;
      z-index: 9999;
      background-color: var(--bg-light);
      width: 100%;
      height: 150px;
      overflow-y: scroll;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;

      .select-item {
        padding: 2px 10px;
      }

      .select-item:hover {
        cursor: pointer;
        color: var(--bg-color);
        background-color: var(--primary-color);
      }
    }
  }
}

li a {
  padding: 5px 10px;
  color: #fff;
  margin-left: 10px;
  border-radius: 5px;
  font-size: var(--xsm-font);
  background-color: var(--alert-color);
}

span {
  cursor: pointer;
}
</style>
