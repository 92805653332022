<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="field">
        <label>Search Location</label>
        <GmapAutocomplete
          class="input"
          @place_changed="setPlace"
          :options="{
            fields: ['geometry', 'formatted_address', 'address_components'],
          }"
        />
      </div>
      <!-- <button class="button primary" @click="addMarker">Add</button> -->
    </div>
    <div class="column is-4">
      <div class="field">
        <label>Latitude</label> <br />
        <div class="control">
          <input type="text" class="input" :value="form.lat" disabled />
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="field">
        <label>Longitude</label> <br />
        <div class="control">
          <input type="text" class="input" :value="form.lng" disabled />
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="field">
        <label>M</label>
        <div class="control">
          <input type="number" class="input" v-model="form.distance" />
        </div>
      </div>
    </div>
    <br />

    <!-- lat{{ center.lat }} <br />
    lng{{ center.lng }} <br /> -->
    <GmapMap
      :center="mapStartLocation"
      :zoom="16"
      style="width: 100%; height: 400px"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="'markers' + index"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @drag="updateCoordinates"
      />
      <GmapCircle
        v-for="(i, index) in circles"
        :key="'circles' + index"
        :radius="radius"
        :center="i"
        :options="circleOptions"
      ></GmapCircle>
    </GmapMap>
  </div>
</template>

<script>
const mapStartLocation = { lat: 17.9576063, lng: 102.6319118 };
const center = { lat: 17.9576063, lng: 102.6319118 };
const markers = [
  {
    position: {
      lat: 17.9576063,
      lng: 102.6319118,
    },
  },
];
const circles = [{ lat: 17.9576063, lng: 102.6319118 }]; 
export default {
  name: "GoogleMap",
  props: ["lat", "lng", "distance", "fetch"],
  data: () => ({
    mapStartLocation: { ...mapStartLocation },
    center: { ...center },
    markers: [...markers],
    circles: [...circles],
    circleOptions: {
      strokeColor: "#76CBA1",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#CFE2F3",
      fillOpacity: 0.35,
    },
    radius: 0,
    form: {
      lat: "",
      lng: "",
      distance: "",
    },
  }),
  watch: {
    "form.lat"(val) {
      this.$emit("emitLat", val);
    },
    "form.lng"(val) {
      this.$emit("emitLng", val);
    },
    "form.distance"() {
      this.radius = parseInt(this.form.distance)
        ? parseInt(this.form.distance)
        : 0;
      this.$emit("emitDistance", this.radius);
    },

    fetch() {
      const mapStartLocation = { lat: 17.9576063, lng: 102.6319118 };
      const center = { lat: 17.9576063, lng: 102.6319118 };
      const markers = [
        {
          position: {
            lat: 17.9576063,
            lng: 102.6319118,
          },
        },
      ];
      const circles = [{ lat: 17.9576063, lng: 102.6319118 }];


      this.form.lat = this.lat;
      this.form.lng = this.lng;
      this.form.distance = this.distance;

      this.center.lat = this.form.lat ?? center.lat;
      this.center.lng = this.form.lng ?? center.lng;

      this.markers[0].position = this.center;

      this.circles[0].lat = this.form.lat ?? circles[0].lat;
      this.circles[0].lng = this.form.lng ?? circles[0].lng;

      this.mapStartLocation.lat = this.form.lat ?? mapStartLocation.lat;
      this.mapStartLocation.lng = this.form.lng ?? mapStartLocation.lng;
    },
  },
  methods: {
    setPlace(place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      this.center.lat = lat;
      this.center.lng = lng;

      this.markers[0].position = this.center;

      this.circles[0].lat = lat;
      this.circles[0].lng = lng;

      this.mapStartLocation.lat = lat;
      this.mapStartLocation.lng = lng;

      this.form.lat = lat;
      this.form.lng = lng;
    },
    updateCoordinates(location) {
      const lat = location.latLng.lat();
      const lng = location.latLng.lng();

      this.center.lat = lat;
      this.center.lng = lng;

      this.form.lat = lat;
      this.form.lng = lng;

      this.circles[0].lat = lat;
      this.circles[0].lng = lng;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>